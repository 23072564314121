import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useContext } from 'react';
import { match } from 'react-router';
import InteractiveCoverPreview from '../../components/InteractiveCoverPreview';
import InteractiveSurveyContext from '../../hooks/InteractiveSurveyContext';

type Props = {
  match: match,
};
const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    background: theme.palette.primary.dark,
    color: theme.palette.common.white,
    position: 'absolute',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  coverPage: {
    marginTop: '49px',
    height: 'calc(100% - 55px)',
  },
  navBar: {
    top: '0px',
    position: 'fixed',
    margin: 'auto',
  },
  coverPageLeft: {
    color: 'red',
  },
  coverPageRight: {
    color: 'yellow',
  },
}));

const InteractiveSurveyCover: React.FC<Props> = ({ match: { params } }) => {
  const { survey } = useContext(InteractiveSurveyContext);
  const classes = useStyles();
  if (!survey || Object.keys(survey).length === 0) {
    return null;
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.coverPage}>
          {survey && <InteractiveCoverPreview order='0' survey={survey} />}
        </div>
      </div>
    </>
  );
};

InteractiveSurveyCover.displayName = 'InteractiveSurveyCover';
export default InteractiveSurveyCover;
