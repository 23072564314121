export const PDFPaperSize = {
  Landscape: 'Landscape',
  Portrait: 'Portrait',
};

// eslint-disable-next-line no-shadow
export enum MediaFileType {
  images = 'IMAGES',
  fliers = 'FLIERS',
  floorplans = 'FLOORPLANS'
}

export const MediaOverlayMap: StringKeyValue = {
  FLIERS: 'Flyer',
  FLOORPLANS: 'Floorplan',
};
export type StringKeyValue = {
  [key: string]: string;
};

export const linkAttributes = ['Website', 'Virtual Tour'];

export const excludedTableFields = ['Address', 'Amenities', 'City', 'Comments', 'Postal Code', 'State Province'];


export const AVAILABILITY_NAME_KEY = 'availabilityName';

export const MULTI_FLOOR_SUITE_FIELD = "availability_info_floor_details"