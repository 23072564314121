import { Typography } from '@cbreone/core-ui';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  createStyles,
  makeStyles,
} from '@material-ui/core';

import DirectionsOutlined from '@material-ui/icons/DirectionsOutlined';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import Header from '../../components/PDFCommon/Header';
import { PDFPaperSize } from '../../data/constants';
import InteractiveSurveyContext from '../../hooks/InteractiveSurveyContext';
import { Property, Survey } from '../../types';
import { BHPropertyFieldRule } from '../../types/bh-property-field-rule';
import * as mapUtil from '../../utilities/map';
import { surveyShortlistedOverride } from '../../utilities';

const useStyles = makeStyles((theme) =>
  createStyles({
    tableCellStopNumber: {
      borderBottom: 'none',
      fontSize: '12px !important',
      padding: '4px 6px 4px 6px',
      whiteSpace: 'pre-line',
      width: '40px',
      wordWrap: 'break-word',
      '&:first-child': {
        paddingLeft: '18px',
      },
      '&:last-child': {
        paddingRight: '18px',
      },
    },
    pinNumbers: {
      color: 'white',
      border: '2px solid white',
      borderRadius: '50%',
      width: '23px',
      height: '23px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 8px',
      fontFamily: 'Arial',
    },
    tableCellRoot2: {
      borderBottom: 'none',
      fontSize: '12px',
      padding: '4px 6px 4px 6px',
      whiteSpace: 'pre-line',
      wordWrap: 'break-word',
      width: '40%',
      '&:first-child': {
        paddingLeft: '18px',
      },
      '&:last-child': {
        paddingRight: '18px',
      },
    },
    tableCellRoot1: {
      borderBottom: 'none',
      fontSize: '12px',
      padding: '4px 6px 4px 6px',
      whiteSpace: 'pre-line',
      wordWrap: 'break-word',
      width: '50px',
      '&:first-child': {
        paddingLeft: '18px',
      },
      '&:last-child': {
        paddingRight: '18px',
      },
    },
    tableCellRoot: {
      borderBottom: 'none',
      fontSize: '12px',
      padding: '4px 6px 4px 6px',
      whiteSpace: 'pre-line',
      wordWrap: 'break-word',
      '&:first-child': {
        paddingLeft: '18px',
      },
      '&:last-child': {
        paddingRight: '18px',
      },
    },
    tableCellLink: {
      borderBottom: 'none',
      cursor: 'pointer',
      width: '100px',
      color: '#003F2D',
      fontSize: '12px',
      padding: '4px 6px 4px 6px',
      whiteSpace: 'pre-line',
      wordWrap: 'break-word',
      '&:first-child': {
        paddingLeft: '18px',
      },
      '&:last-child': {
        paddingRight: '18px',
      },
    },
    container: {
      boxShadow: 'none',
      borderRadius: '0px',
    },
    additionalContainer: {
      boxShadow: 'none',
      borderRadius: '0px',
      paddingTop: '24px',
    },
    imgCell: {
      paddingTop: '16px',
    },
    oddRow: {
      background: theme.palette.common.white,
    },
    evenRow: {
      background: theme.palette.grey['100'],
    },
    nameCell: {
      fontWeight: 400,
      fontSize: '14px !important',
    },
    captionText: {
      color: 'rgba(26, 26, 26, 0.6)',
      fontSize: '12px',
      fontWeight: 400,
      minHeight: '20px',
    },
    pageContainerLandscapeNoHeaderHeight: {
      height: '8in',
    },
    pageContainerLandscapeHeaderHeight: {
      height: '7in',
    },
    pageContainePortraitNoHeaderHeight: {
      height: 'calc(100vh - 150px)',
    },
    pageContainerPortraitHeaderHeight: {
      height: '10.5in',
    },
    portraitTxtStyle: {
      fontSize: '12px',
    },
    landscapeTxtStyle: {
      fontSize: '14px',
    },
    footer: {
      width: '100%',
      backgroundColor: '#cccccc',
      height: '0.5in',
    },
    link: {
      fontStyle: 'normal',
      fontFamily: 'Calibre-R',
      letterSpacing: '0.1px',
      wordWrap: 'break-word',
    },
    emptyStateMessage: {
      margin: '20px',
    },
    goLinkFont: {
      fontSize: '16px',
      fontWeight: 500,
      color: '#003F2D',
    },
  }),
);

const TourScheduleSheet: React.FC<{}> = () => {
  const { survey } = useContext(InteractiveSurveyContext);

  const classes = useStyles();
  let curHeight = 94; // header height
  const landscapeMode = survey.pdfPaperSize === PDFPaperSize.Landscape;
  const maxPageHeight = landscapeMode ? 710 : 950; // pdf page height
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const [isLoading, setIsLoading] = useState(true);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  const { getIconData } = mapUtil.default;
  const shortlisted = (localStorage.getItem('shortlisted') === 'true');
  window.addEventListener('resize', () => {
    setIsDesktop(window.innerWidth >= 1024);
  });

  const extraFieldsPagesTmp: any[][] = [];
  let currentPageIdx = 0;
  useEffect(() => {
    setIsLoading(true);
  }, [survey]);

  const openGoogleMap = (
    latitude: string,
    longitude: string,
    address1: string,
    city: string,
    stateProvince: string,
    postalCode: string,
  ) => {
    const googleUrl = `https://www.google.com/maps/dir//${address1},${city},+${stateProvince}+${postalCode}/@${latitude},${longitude}`;
    return window.open(googleUrl);
  };

  const addToRefs = (el: HTMLTableRowElement, row?: BHPropertyFieldRule) => {
    if (!isLoading) {
      return;
    }

    if (el) {
      const index = Math.trunc(
        (el.offsetHeight + curHeight + 24) / maxPageHeight,
      );
      if (row) {
        // the first row of the next page
        if (index > currentPageIdx) {
          // calculate the remaining space of the first page.
          curHeight = index * maxPageHeight;
        }

        const currentLoop: any[] = extraFieldsPagesTmp[index] || [];
        extraFieldsPagesTmp[index] = [...currentLoop, row];
        currentPageIdx = index;
      }
      if (index <= currentPageIdx) {
        curHeight += el.offsetHeight;
      }
    }
  };

  const pageHeightClassName = landscapeMode
    ? classes.pageContainerLandscapeHeaderHeight
    : classes.pageContainePortraitNoHeaderHeight;
  const textClass = landscapeMode
    ? classes.landscapeTxtStyle
    : classes.portraitTxtStyle;

  const getCurrentDate = () => {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const jsDate = new Date();

    const day = days[jsDate.getDay()];
    const month = months[jsDate.getMonth()];
    const date = jsDate.getDate();
    const year = jsDate.getFullYear();

    return `${day} ${month} ${date}, ${year}`;
  };

  if (!survey.id) {
    return <div></div>;
  }

  return (
    <>
      <Header
        title={`Tour Schedule - ${survey.tourDetails || getCurrentDate()}`}
      />
      <TableContainer
        className={`${classes.container} ${pageHeightClassName}`}
        component={Paper}
      >
        <Table size="medium">
          <TableBody>
            {!survey.properties.length ? (
              <Typography
                variant="body2"
                className={classes.emptyStateMessage}
              ></Typography>
            ) : (
              <React.Fragment>
                {survey.properties
                  .filter(
                    (item: Property) =>
                      (!item.isHidden === undefined ||
                        surveyShortlistedOverride(shortlisted, item)) &&
                      !item.metaData?.isCurrentLocation,
                  )
                  .map((scheduleItem, index) => (
                    <TableRow
                      key={scheduleItem.id}
                      className={`${
                        index % 2 ? classes.oddRow : classes.evenRow
                      }`}
                      style={{ height: '46px' }}
                      ref={(node) => (node ? addToRefs(node) : null)}
                    >
                      <TableCell
                        classes={{ root: classes.tableCellRoot1 }}
                        style={{ width: isDesktop ? '40% !important' : '50px' }}
                        scope="row"
                      >
                        <Typography
                          variant="body2"
                          className={`${classes.pinNumbers} ${textClass}`}
                          style={{
                            backgroundColor:
                              getIconData(scheduleItem).iconColor,
                          }}
                        >
                          {index + 1}
                        </Typography>
                      </TableCell>
                      <TableCell
                        classes={{ root: classes.tableCellRoot }}
                        scope="row"
                      >
                        <Typography
                          variant="body2"
                          className={`${classes.nameCell} ${textClass}`}
                        >
                          {scheduleItem.name || '—'}{' '}
                          {scheduleItem.address1 &&
                            scheduleItem.address1 !== scheduleItem.name &&
                            '-'}{' '}
                          {(scheduleItem.address1 &&
                            scheduleItem.address1 !== scheduleItem.name &&
                            scheduleItem.address1) ||
                            ''}
                        </Typography>
                        {!isDesktop && scheduleItem.metaData?.stopDetails && (
                          <Typography
                            variant="body2"
                            className={classes.captionText}
                          >
                            {scheduleItem.metaData?.stopDetails || ''}
                          </Typography>
                        )}
                      </TableCell>

                      {isDesktop && (
                        <TableCell
                          classes={{ root: classes.tableCellRoot2 }}
                          scope="row"
                        >
                          <Typography
                            variant="body2"
                            className={classes.captionText}
                          >
                            {scheduleItem.metaData?.stopDetails || ''}
                          </Typography>
                        </TableCell>
                      )}

                      <TableCell
                        classes={{ root: classes.tableCellLink }}
                        scope="row"
                      >
                        <Grid container justifyContent="flex-end">
                          <DirectionsOutlined
                            onClick={() => {
                              const {
                                latitude,
                                longitude,
                                address1,
                                city,
                                stateProvince,
                                postalCode,
                              } = scheduleItem;
                              openGoogleMap(
                                latitude,
                                longitude,
                                address1,
                                city,
                                stateProvince,
                                postalCode,
                              );
                            }}
                          />
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export type Props = {
  firstPage: boolean;
  pageMode?: string;
  survey: Survey;
};

TourScheduleSheet.displayName = 'TourScheduleSheet';
export default TourScheduleSheet;
