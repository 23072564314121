import React, { useContext, useEffect } from 'react';
import { Link, match } from 'react-router-dom';
import {
  AppBar,
  createStyles,
  makeStyles,
  Theme,
  Toolbar,
  Link as MUILink,
} from '@material-ui/core';
import {
  DEAFULT_DISCLAIMER_URL,
  COUNTY_DISCLAIMER_URL_MAP,
} from '@marketiq/marketiq-core-data';
import CBRELogo from '../../interactive/Icons';
import { PropertyRouteParams } from '../../routes/interactive.routes';
import InteractiveSurveyContext from '../../hooks/InteractiveSurveyContext';
import SectionTabs from '../SectionTabs';
import { getSurvey } from '../../services/survey-service';

type Props = {
  match: match;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      height: 48,
      backgroundColor: theme.palette.primary.dark,
      position: 'fixed',
    },
    subHeader: {
      height: 48,
      minHeight: 48,
    },
    active: {
      color: theme.palette.primary.light,
      paddingLeft: 32,
      display: 'flex',
      font: 'Calibre-R',
      fontSize: 14,
      textUnderlinePosition: 'under',
    },
    inActive: {
      textDecoration: 'none',
      paddingLeft: 32,
      display: 'flex',
      font: 'Calibre-R',
      color: theme.palette.primary.contrastText,
      fontSize: 14,
      '&:hover': {
        color: theme.palette.primary.light,
      },
      '@media (max-width: 767px)': {
        paddingLeft: 0,
      },
    },
    disclaimer: {
      textDecoration: 'none',
      display: 'flex',
      position: 'absolute',
      top: 13,
      right: 16,
      font: 'Calibre-R',
      color: theme.palette.primary.contrastText,
      fontSize: 14,
      '&:hover': {
        color: theme.palette.primary.light,
        textDecoration: 'none',
      },
      '@media (max-width: 767px)': {
        display: 'none',
      },
    },
  })
);

const InteractiveSurveyNavigation: React.FC<Props> = ({
  match: { params },
}) => {
  const classes = useStyles();
  const { surveyId } = params as PropertyRouteParams;
  const { survey, setSurvey } = useContext(InteractiveSurveyContext);
  const disclaimerUrl = COUNTY_DISCLAIMER_URL_MAP[survey.surveyCountry] || DEAFULT_DISCLAIMER_URL;

  useEffect(() => {
    const resopnseHandler = (data: any) => setSurvey(data);
    if (!survey.id) {
      getSurvey(surveyId)
        .then((data) => resopnseHandler(data))
        // eslint-disable-next-line no-console
        .catch((error) => console.log(error));
    }
  }, [surveyId]);

  return (
    <AppBar position="static" className={classes.header}>
      <Toolbar className={classes.subHeader}>
        <Link to={`/${surveyId}`} className={classes.inActive}>
          <CBRELogo />
        </Link>
        {survey.sections && (
          <SectionTabs
            sectionList={survey.sections}
            surveyCountry={survey.surveyCountry}
            propertyList={survey.properties}
            surveyId={surveyId}
          />
        )}
        <MUILink
          href={disclaimerUrl}
          target="_blank"
          className={classes.disclaimer}
        >
          Disclaimer
        </MUILink>
      </Toolbar>
    </AppBar>
  );
};

InteractiveSurveyNavigation.displayName = 'InteractiveSurveyNavigation';
export default InteractiveSurveyNavigation;
