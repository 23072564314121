/* eslint-disable jsx-a11y/alt-text */
import { Typography, externalTheme } from '@cbreone/core-ui';
import {
  Box,
  Button,
  Grid,
  Theme,
  Tooltip,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useReducer,
} from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { match } from 'react-router';
import { useHistory } from 'react-router-dom';
import { BAMWSO2ClientID, BAMWSO2SecretID } from '../../config';
import InteractiveSurveyContext from '../../hooks/InteractiveSurveyContext';
import LeftArrowIcon from '../../interactive/Icons/LeftArrowIcon';
import RightArrowIcon from '../../interactive/Icons/RightArrowIcon';
import { BAM_MAP_STYLE_SOURCES } from '../../map/constants/MapStyles';
import { PropertyRouteParams } from '../../routes/interactive.routes';
import { getActiveMediaFilesForProperty } from '../../services/interactive/data-service';
import { Property, PropertyPatch } from '../../types';
import {
  flattenMediaFilesWithModifiedFiles,
  getFullAddressStr,
  surveyShortlistedOverride,
} from '../../utilities';
import * as mapUtil from '../../utilities/map';
import InteractivePropertyDetail from './InteractivePropertyDetail';
import InteractiveSurveyPropertyImageModal from './Gallery';
import useInteractiveAPI from '../../services/interactive/useInteractiveAPI';
import { BHPropertyFieldRule } from '../../types/bh-property-field-rule';
import PropertyAvailabilities from './PropertyAvailabilities';
import { MediaFileType, MediaOverlayMap } from '../../data/constants'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: theme.palette.grey['200'],
      display: 'flex',
      height: '104px',
      width: '100%',
      position: 'fixed',
    },
    subtitle: {
      backgroundColor: theme.palette.grey['600'],
      display: 'flex',
      height: '40px',
      width: '100%',
    },
    subtitleText: {
      fontFamily: 'Calibre-R',
      fontSize: '16px',
      color: theme.palette.primary.contrastText,
      display: 'flex',
      letterSpacing: '-0.01em',
      lineHeight: '20px',
      alignItems: 'center',
      paddingLeft: '24px',
    },
    headerText: {
      color: theme.palette.primary.main,
      paddingTop: 24,
      display: 'block',
      marginTop: -7,
      [theme.breakpoints.only('md')]: {
        paddingTop: 28,
      },
      [theme.breakpoints.only('sm')]: {
        paddingTop: 28,
      },
    },
    outerSpace: {
      padding: '40px',
      width: '100vw',
      alignSelf: 'start',
      '@media (max-width: 767px)': {
        padding: '6px',
      },
    },
    availabilities: {
      background: '#fff',
      padding: '40px 50px 20px 50px',

      '@media(max-width:1279px)': {
        padding: '40px 0px 20px 32px',
      },
      '@media(max-width:767px)': {
        padding: '40px 0px 20px 6px',
      },
    },
    title: {
      [theme.breakpoints.only('md')]: {
        fontSize: '24px',
      },
      [theme.breakpoints.only('sm')]: {
        fontSize: '24px',
      },
      '@media (max-width: 767px)': {
        fontSize: '18px',
      },
    },
    addressText: {
      fontSize: '14px',
      '@media (max-width: 767px)': {
        fontSize: '12px',
      },
    },
    headerSecondaryText: {
      fontFamily: 'Calibre-R',
      fontSize: '24px',
      letterSpacing: '-0.01em',
      lineHeight: '32px',
      paddingBottom: 38,
      '@media (max-width: 767px)': {
        fontSize: '20px',
      },
    },
    paginationButton: {
      '@media (max-width: 767px)': {
        minWidth: '46px',
        padding: '6px 0px',
      },
    },
    navigation: {
      paddingRight: 40,
      '@media (max-width: 767px)': {
        paddingRight: 0,
      },
    },
    innerNavigation: {
      paddingTop: 24,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    indexCount: {
      '@media (max-width: 767px)': {
        fontSize: '12px',
        padding: 0,
      },
    },
    order: {
      color: theme.palette.secondary.light,
      paddingTop: 24,
      display: 'flex',
      paddingRight: 20,
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    propHeaderTitle: {
      [theme.breakpoints.only('md')]: {
        paddingLeft: 47,
      },
      [theme.breakpoints.only('sm')]: {
        paddingLeft: 67,
      },
      [theme.breakpoints.only('lg')]: {
        paddingLeft: 20,
      },
    },
    verticalLine: {
      borderLeft: `1px solid ${theme.palette.grey['800']}`,
      height: '65px',
      marginLeft: '18px',
    },
    imageStyle: {
      width: '100%',
      height: '80%',
      maxHeight: '590px',
      objectFit: 'cover',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      cursor: 'pointer',
      [theme.breakpoints.only('md')]: {
        height: '258px',
      },
      [theme.breakpoints.only('sm')]: {
        height: '258px',
        width: '259px',
      },
      '@media (max-width: 767px)': {
        height: '220px',
        width: '100vw',
        // padding: 10
      },
    },
    smallImageStyle: {
      height: '360px',
      width: '100%',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      cursor: 'pointer',
    },
    secondaryImageStyle: {
      height: '320px',
      width: '100%',
      backgroundPositionX: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      cursor: 'pointer',
      position: 'relative',
      [theme.breakpoints.only('md')]: {
        height: '258px',
      },
      [theme.breakpoints.only('sm')]: {
        height: '258px',
        width: '259px',
      },
    },
    imageStyleShape: {
      [theme.breakpoints.only('md')]: {
        paddingRight: 0,
      },
      [theme.breakpoints.only('sm')]: {
        paddingRight: 0,
      },
      [theme.breakpoints.only('lg')]: {
        paddingRight: 2,
      },
    },
    arrow: {
      width: '48px',
      height: '48px',
    },
    content: {
      flexGrow: 1,
      width: '100%',

      '@global': {
        '.custom-popover': {
          display: 'none !important',
        },
      },
    },
    propertyDetailContainer: {
      position: 'relative',
      background: '#fff',
      paddingTop: 104,
    },
    overflowEffect: {
      position: 'relative',
      overflowY: 'auto',
    },
    imgListBox: {
      alignSelf: 'start',
      backgroundColor: '#FFFFFF',
    },

    publishShareModalSection: {
      textAlign: 'right',
      color: externalTheme.palette.primary.contrastText,
      backgroundColor: externalTheme.palette.primary.main,
      textTransform: 'none',
      minWidth: '143px',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '1.5',
      '&:hover': {
        backgroundColor: externalTheme.palette.primary.main,
      },
      '@media (max-width: 767px)': {
        minWidth: '100%',
        height: '100%',
        borderRadius: 0,
      },
    },
    buttonGrid: {
      display: 'inline',
    },

    mobileBox: {
      display: 'none',
      '@media (max-width: 767px)': {
        display: 'block',
      },
    },
    desktopBox: {
      display: 'block',
      '@media (max-width: 767px)': {
        display: 'none',
      },
    },
    propertyDetailText: {
      color: theme.palette.primary.main,
      paddingBottom: '16px',
    },
    commentsAmenitiesHeaders: {
      color: theme.palette.primary.main,
      paddingBottom: '16px',
      paddingTop: '40px',
    },
    commentsAmenitiesFonts: {
      fontFamily: 'Calibre-R',
      fontSize: '16px',
      lineHeight: '24px',
    },
    propertyList: {
      color: theme.palette.text.primary,
    },
    overlay: {
      position: 'absolute',
      bottom: 0,
      minHeight: '13%',
      backgroundColor: '#435354',
      opacity: '90%',
      color: '#fff',
      paddingLeft: '24px',
      width: '100%',
      display: 'inline-flex',
      alignItems: 'center',
    },
    flyerBox: {
      backgroundColor: 'rgba(0,63,45,0.1)',
      borderRadius: '16px',
      width: 'fit-content',
      margin: '3px',
      padding: '4px 10px 1px 10px',
      height: '32px',
      float: 'left',
    },
    flyerDiv: {
      backgroundRepeat: 'no-repeat',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      paddingLeft: '25px',
      paddingBottom: '2px',
      cursor: 'pointer',
    },
    icon: {
      color: externalTheme.palette.primary.main,
      verticalAlign: 'middle',
      marginLeft: '20px',
      '@media (max-width: 767px)': {
        position: 'fixed',
        bottom: '75px',
        right: '20px',
        backgroundColor: '#003F2D',
        borderRadius: '4px',
        width: '30px',
        height: '30px',
        padding: '5px',
        color: '#FFFFFF',
      },
    },
    iconMobile: {
      color: externalTheme.palette.primary.main,
      verticalAlign: 'middle',
      marginLeft: '20px',
      '@media (min-width: 766px)': {
        position: 'fixed',
        bottom: '75px',
        right: '20px',
        backgroundColor: '#003F2D',
        borderRadius: '4px',
        width: '30px',
        height: '30px',
        padding: '5px',
        color: '#FFFFFF',
      },
    },
    customWidth: {
      maxWidth: 200,
      backgroundColor: theme.palette.common.black,
      fontFamily: 'Calibre-R',
      fontSize: '14px',
      lineHeight: '21px',
      alignItems: 'center',
    },
    tipArrow: {
      color: theme.palette.common.black,
    },
    shortlisted: {
      textAlign: 'right',
      color: externalTheme.palette.primary.contrastText,
      backgroundColor: externalTheme.palette.primary.main,
      textTransform: 'none',
      width: '82px',
      height: '24px',
      fontSize: '10px',
      fontWeight: 400,
      lineHeight: '16px',
      position: 'absolute',
      padding: '0px -8px 0px 0px',
      bottom: '60px',
      '& .MuiChip-label': {
        paddingLeft: '8px',
      },
      '&:focus': {
        backgroundColor: externalTheme.palette.primary.main,
      },
      '&:hover': {
        backgroundColor: externalTheme.palette.primary.main,
      },
    },
    shortlist: {
      textAlign: 'right',
      color: externalTheme.palette.primary.main,
      backgroundColor: externalTheme.palette.grey[900],
      textTransform: 'none',
      width: '82px',
      height: '24px',
      fontSize: '10px',
      fontWeight: 500,
      lineHeight: '16px',
      borderRadius: 16,
      position: 'absolute',
      bottom: '60px',
      '& .MuiChip-label': {
        paddingLeft: '8px',
      },
      '&:hover': {
        backgroundColor: externalTheme.palette.grey[900],
      },
      '&:focus': {
        backgroundColor: externalTheme.palette.grey[900],
      },
    },
    chip: {
      width: '10.26px',
      height: '7.82px',
      backgroundColor: externalTheme.palette.primary.main,
      color: externalTheme.palette.primary.contrastText,
    },
    chipShortList: {
      width: '12px',
      height: '12px',
      backgroundColor: externalTheme.palette.grey[900],
      color: externalTheme.palette.primary.main,
    },
    chipRoot: {
      width: '200px',
      '@media (max-width: 767px)': {
        width: '96px',
        marginBottom: '20px',
      },
    },
    paddingBottom125: {
      '@media (max-width: 767px)': {
        paddingBottom: '125px',
      },
    },
  }),
);

interface PropertyImages {
  id?: number;
  url: string;
  mediaType: string;
  pdf?: string;
  downloadUrl?: string;
  displayName?: string;
  origin?: string;
}

type Props = {
  match: match;
};

const mediumPlaceholderImg = '/assets/images/image-placeholder-medium.png';
const flyerIconImg = '/assets/images/flyer-icon.svg';
const floorPlanIconImg = '/assets/images/floorplan-icon.svg';

const InteractiveSurveyProperty: React.FC<Props> = ({ match: { params } }) => {
  const { surveyId, propertyId } = params as PropertyRouteParams;
  const { survey } = useContext(InteractiveSurveyContext);
  const [propertyList, setPropertyList] = useState<Property[]>([]);
  const [images, setImages] = useState<PropertyImages[]>([]);
  const [floorplan, setFloorplan] = useState<PropertyImages[]>([]);
  const [flier, setFlier] = useState<PropertyImages[]>([]);
  const { width, ref } = useResizeDetector();
  const [isCommentVisible, setIsCommentVisible] = useState<boolean>(false);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [isAmenitiesVisible, setIsAmenitiesVisible] = useState<boolean>(false);
  const [activeAvailabilityRules, setActiveAvailabilityRules] =
    useState<any[]>();
  const [shortlisted, setShortlisted] = useState(
    localStorage.getItem('shortlisted') === 'true',
  );
  const { updateMapPoints } = mapUtil.default;
  const imgBoxRef = useRef<any>(null);
  const propertyDetailsRef = useRef<any>(null);

  const { updateInteractiveProperty } = useInteractiveAPI(surveyId);
  const history = useHistory();
  const property = useMemo(() => {
    if (survey?.properties) {
      return survey.properties.find((item: any) => item.id === propertyId);
    }
    return {} as Property;
  }, [propertyId, survey]);

  const mapCompEle = useRef<any>();
  const classes = useStyles();

  const changeArrowBackground = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.style.background = 'none';
  };

  const setMapStyle = () => {
    if (mapCompEle?.current && mapCompEle.current.updateMapStyles) {
      mapCompEle.current.updateMapStyles(
        BAM_MAP_STYLE_SOURCES[survey?.propertyMapStyle] ||
          BAM_MAP_STYLE_SOURCES.cbreLight,
      );
    }
  };

  useEffect(() => {
    setClickedMedia(null);
    if (property?.latitude && property?.longitude) {
      updateMapPoints([property] || [], mapCompEle, '', 15, 1);
      if (mapCompEle?.current && mapCompEle?.current.mapResize) {
        mapCompEle.current.updateMapStyles(BAM_MAP_STYLE_SOURCES.esriHybrid);
      }
    }
    if (mapCompEle?.current) {
      // need about 1 sec before calling setMapStyle to avoid unstyled map issue
      setTimeout(setMapStyle, 1000);
    }
  }, [property, propertyId, updateMapPoints, setMapStyle]);

  useEffect(() => {
    if (mapCompEle?.current && mapCompEle.current.mapResize) {
      mapCompEle.current.mapResize(width);
    }
  }, [width]);

  useEffect(() => {
    if (survey?.properties) {
      const activePropertyList = survey.properties.filter(
        (item: Property) =>
          !item.metaData?.isCurrentLocation &&
          surveyShortlistedOverride(shortlisted, item),
      );
      setPropertyList(activePropertyList);
    }
    const availabilityRules =
      survey?.surveyPropertyAvailabilityRule
        ?.filter(
          (rule) =>
            rule.status === 'ACTIVE' && rule.fieldKey !== 'proposedSpace',
        )
        .sort((first: any, second: any) => first.order - second.order) || [];
    setActiveAvailabilityRules(availabilityRules);
    if (survey && survey.surveyPropertyFieldRule) {
      const isCommentAvailable = survey.surveyPropertyFieldRule.find(
        (rule: any) => rule.status === 'ACTIVE' && rule.fieldKey === 'comments',
      );

      const isAmenitiesAvailable = survey.surveyPropertyFieldRule.find(
        (rule: any) =>
          rule.status === 'ACTIVE' && rule.fieldKey === 'amenities',
      );
      setIsCommentVisible(!!isCommentAvailable);
      setIsAmenitiesVisible(!!isAmenitiesAvailable);
    }
  }, [survey, reducerValue]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [clickedMedia, setClickedMedia] = React.useState<any>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);
  const [imagesFetched, setImageFetched] = useState<boolean>(false);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  useEffect(() => {
    setImageFetched(false);
    setImageLoaded(false);
    if (imgBoxRef?.current) {
      imgBoxRef.current.scrollTop = 0;
    }
    window.scrollTo(0, 0);
    const getImagesForProperty = async () => {
      const response: any = await getActiveMediaFilesForProperty(
        surveyId,
        propertyId,
        'IMAGES',
      );
      const propertyImages: PropertyImages[] = response.media.map(
        (propertyImg: any) => ({
          id: propertyImg.mediaId,
          url: propertyImg.cloudfrontUrl,
          mediaType: propertyImg.mediaType,
        }),
      );
      setImages(propertyImages);
      return propertyImages;
    };

    const getFloorplanForProperty = async () => {
      const response: any = await getActiveMediaFilesForProperty(
        surveyId,
        propertyId,
        'FLOORPLANS',
      );
      const propertyFloorplan: PropertyImages[] = response.media.map(
        (propertyImg: any) => {
          if (propertyImg?.displayName?.includes('.pdf')) {
            return {
              id: propertyImg.mediaId,
              url: propertyImg.pdfImageUrl,
              mediaType: propertyImg.mediaType,
              pdf: propertyImg.cloudfrontUrl,
              downloadUrl: propertyImg.cloudfrontUrl,
              displayName: propertyImg.displayName,
              origin: propertyImg.origin ? propertyImg.origin : 'sc',
            };
          }
          return {
            id: propertyImg.mediaId,
            url: propertyImg.cloudfrontUrl,
            mediaType: propertyImg.mediaType,
            downloadUrl: propertyImg.cloudfrontUrl,
            displayName: propertyImg.displayName,
            origin: propertyImg.origin ? propertyImg.origin : 'sc',
          };
        },
      );
      setFloorplan(propertyFloorplan);
      return propertyFloorplan;
    };

    const getFlierForProperty = async () => {
      const response: any = await getActiveMediaFilesForProperty(
        surveyId,
        propertyId,
        'FLIERS',
      );
      const mediaFiles = flattenMediaFilesWithModifiedFiles(response.media);

      const propertyFlier: PropertyImages[] = mediaFiles.map(
        (propertyImg: any) => ({
          id: propertyImg.mediaId,
          url: propertyImg.pdfImageUrl,
          mediaType: propertyImg.mediaType,
          pdf: propertyImg.cloudfrontUrl,
          downloadUrl: propertyImg.cloudfrontUrl,
        }),
      );
      setFlier(propertyFlier);
      return propertyFlier;
    };

    (async () => {
      let propertyImage = await getImagesForProperty();
      if (!survey.isIncludePhotos && propertyImage) {
        propertyImage = [propertyImage[0]];
      }
      if (survey.isIncludeSitePlans) {
        await getFloorplanForProperty();
      }
      if (survey.isIncludeFlyers) {
        await getFlierForProperty();
      }
      setImageFetched(!!property?.id);
    })();
  }, [
    property,
    propertyId,
    survey.isIncludeFlyers,
    survey.isIncludePhotos,
    survey.isIncludeSitePlans,
    surveyId,
  ]);

  useEffect(() => {
    function onResize() {
      setIsDesktop(window.innerWidth >= 768);
      calculateContainerHeights();
    }

    onResize();

    window.addEventListener('resize', debounce(onResize, 100));

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [
    imageLoaded,
    imagesFetched,
    imgBoxRef?.current,
    propertyDetailsRef?.current,
  ]);

  const calculateContainerHeights = useCallback(() => {
    if (imagesFetched && imgBoxRef?.current && propertyDetailsRef?.current) {
      imgBoxRef.current.style.height = 'auto';
      propertyDetailsRef.current.style.height = 'auto';

      const imgBoxHeight = imgBoxRef.current.offsetHeight;
      const detailsHeight = propertyDetailsRef.current.offsetHeight;

      const maxHeight = window.innerHeight - 152;

      if (maxHeight < imgBoxHeight || maxHeight < detailsHeight) {
        if (maxHeight < imgBoxHeight) {
          imgBoxRef.current.style.height = `${maxHeight}px`;
        }
        if (maxHeight < detailsHeight) {
          propertyDetailsRef.current.style.height = `${maxHeight}px`;
        }
      }
    }
  }, [imagesFetched, imgBoxRef?.current, propertyDetailsRef?.current]);

  const addressDisplay: string = property
    ? getFullAddressStr(
      property.address1,
      property.city,
      property.state,
      property.postalCode,
    )
    : '';

  const getPropertyOrder = () => {
    if (survey?.properties) {
      return survey.properties
        .filter(
          (item: Property) =>
            !item.metaData?.isCurrentLocation &&
            surveyShortlistedOverride(shortlisted, item),
        )
        .sort((first: Property, second: Property) => first.order - second.order)
        .findIndex((item: Property) => item.id === propertyId);
    }
    return 1;
  };

  const nextProperty = () => {
    if (survey?.properties && propertyList.length > 0) {
      const order = getPropertyOrder() + 1;
      const { id } = propertyList[order] || undefined;
      history.push(`/${surveyId}/property/${id}`);
    }
  };

  const previousProperty = () => {
    if (survey?.properties) {
      const order = getPropertyOrder() - 1;
      const { id } = propertyList[order];
      history.push(`/${surveyId}/property/${id}`);
    }
  };

  if (getPropertyOrder() + 1 === 0 && propertyList.length > 0) {
    nextProperty();
  }
  const propertyComments = () => {
    if (property?.comments) {
      return (
        <>
          <Typography variant="h6" className={classes.commentsAmenitiesHeaders}>
            Comments
          </Typography>
          <Typography className={classes.commentsAmenitiesFonts}>
            {property.comments}
          </Typography>
        </>
      );
    }
    return '';
  };

  const propertyAminities = () => {
    if (property?.amenities) {
      return (
        <>
          <Typography variant="h6" className={classes.commentsAmenitiesHeaders}>
            Amenities
          </Typography>
          <Typography className={classes.commentsAmenitiesFonts}>
            {property.amenities}
          </Typography>
        </>
      );
    }
    return '';
  };

  const getFlierTitle = (str: string | undefined) => {
    // capture everything between the last '/' character and last '_' character
    const regex = /\/([^/]+)\.[^.]+$/;
    const matches = str?.match(regex);
    let result;
    if (matches && matches.length > 1) {
      [, result] = matches;
    } else {
      result = 'Flyer';
    }
    return result;
  };

  const getFloorplanTitle = (str: string | undefined) => {
    // capture everything before the '.' character
    const regex = /^(.*?)\./;
    const matches = str?.match(regex);
    let result;
    if (matches && matches.length > 1) {
      [, result] = matches;
    } else {
      result = 'Floor Plan';
    }
    return result;
  };

  const attachmentsMobileView = () => (
    <div style={{ margin: '8px' }}>
      {flier?.length > 0 &&
        flier.map((item, index) => (
          <div
            className={classes.flyerBox}
            title={getFlierTitle(item.url)}
            key={index}
          >
            <div
              className={classes.flyerDiv}
              role="button"
              style={{ backgroundImage: `url('${flyerIconImg}')` }}
              onClick={() => window.open(item.downloadUrl, '_blank')}
            >
              {`Flyer ${index + 1}`}
            </div>
          </div>
        ))}
      {floorplan?.length > 0 &&
        floorplan.map((item, index) => (
          <div
            className={classes.flyerBox}
            key={index}
            title={getFloorplanTitle(item.displayName)}
          >
            <div
              className={classes.flyerDiv}
              role="button"
              style={{ backgroundImage: `url('${floorPlanIconImg}')` }}
              onClick={() => window.open(item.downloadUrl, '_blank')}
            >
              {`Floor Plan ${index + 1}`}
            </div>
          </div>
        ))}
    </div>
  );

  const shortListClicked = () => {
    if (property) {
      if (!property.metaData) {
        property.metaData = {};
      }
      property.metaData.isClientShortlist = !property.metaData?.isClientShortlist;
      updateInteractiveProperty(survey, property as PropertyPatch, surveyId);
      updateMapPoints([property] || [], mapCompEle, '', 15, 1);
      forceUpdate();
    }
  };

  const onUpdateSurvey = () => {
    setShortlisted(!shortlisted);
    shortListClicked();
    forceUpdate();
  };

  const getGridClass = (property: any) =>
    activeAvailabilityRules &&
    (property?.availabilities || []).length > 0
      ? ''
      : classes.paddingBottom125;

  const backGroundImageStyle = (item: PropertyImages) => {
    let overlayText = '';
    if (item?.mediaType && MediaOverlayMap[item.mediaType]) {
      overlayText = MediaOverlayMap[item.mediaType];
    }
    return (
      <Grid item sm={12} md={12} lg={6} className={classes.imageStyleShape}>
        <div
          role="button"
          onClick={() => {
            setClickedMedia(item.id);
            setOpen(true);
          }}
        >
          <div
            style={{ backgroundImage: `url('${item.url}')` }}
            className={classes.secondaryImageStyle}
          >
            {overlayText && (
              <span className={classes.overlay}>{overlayText}</span>
            )}
          </div>
        </div>
      </Grid>
    );
  };

  return (
    <>
      {property && (
        <>
          <Grid container>
            <Grid item xs={12} sm={12} className={classes.header}>
              <Grid item xs={9} sm={8} md={9} lg={9}>
                <Grid container className={classes.propHeaderTitle}>
                  <Grid
                    item
                    xs={3}
                    sm={1}
                    md={1}
                    lg={1}
                    className={classes.order}
                  >
                    <Typography className={classes.headerSecondaryText}>
                      {getPropertyOrder() + 1}
                    </Typography>
                    <div className={classes.verticalLine} />
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    sm={11}
                    md={11}
                    lg={11}
                    className={classes.headerText}
                  >
                    {property.name !== 'Untitled Property' && (
                      <Typography variant="h4" className={classes.title}>
                        {property.name}
                      </Typography>
                    )}
                    {property.name !== 'Untitled Property' &&
                      property.address1 && (
                      <Typography
                        variant="body2"
                        className={classes.addressText}
                      >
                        {addressDisplay}
                      </Typography>
                    )}
                    {property.name === 'Untitled Property' &&
                      property.address1 && (
                      <>
                        <Typography variant="h4" className={classes.title}>
                          {property.address1}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={classes.addressText}
                        >
                          {property.city}, {property.stateProvince}{' '}
                          {property.postalCode}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={3}
                sm={4}
                md={3}
                lg={3}
                className={classes.navigation}
              >
                {survey?.properties && (
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className={classes.innerNavigation}
                    >
                      {getPropertyOrder() !== 0 ? (
                        <Button
                          className={classes.paginationButton}
                          onMouseOver={changeArrowBackground}
                          onClick={() => previousProperty()}
                        >
                          <LeftArrowIcon
                            stroke={externalTheme.palette.primary.main}
                          ></LeftArrowIcon>
                        </Button>
                      ) : (
                        <Button
                          className={classes.paginationButton}
                          onMouseOver={changeArrowBackground}
                        >
                          <LeftArrowIcon
                            stroke={externalTheme.palette.grey['600']}
                          ></LeftArrowIcon>
                        </Button>
                      )}

                      {isDesktop ? (
                        <Typography
                          variant="body2"
                          className={classes.indexCount}
                        >
                          {getPropertyOrder() + 1} of {propertyList.length}
                        </Typography>
                      ) : (
                        <Typography
                          variant="body2"
                          className={classes.indexCount}
                        >
                          {getPropertyOrder() + 1}/{propertyList.length}
                        </Typography>
                      )}

                      {getPropertyOrder() !== propertyList.length - 1 ? (
                        <Button
                          onMouseOver={changeArrowBackground}
                          className={classes.paginationButton}
                          onClick={() => nextProperty()}
                        >
                          <RightArrowIcon
                            stroke={externalTheme.palette.primary.main}
                          ></RightArrowIcon>
                        </Button>
                      ) : (
                        <Button
                          className={classes.paginationButton}
                          onMouseOver={changeArrowBackground}
                        >
                          <RightArrowIcon
                            stroke={externalTheme.palette.grey['600']}
                          ></RightArrowIcon>
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid container className={classes.propertyDetailContainer}>
              {isDesktop ? (
                <Grid
                  item
                  sm={4}
                  md={4}
                  lg={6}
                  className={`${classes.imgListBox} ${classes.overflowEffect}`}
                  ref={imgBoxRef}
                >
                  <img
                    src={
                      images && images.length > 0
                        ? images[0].url
                        : mediumPlaceholderImg
                    }
                    className={classes.imageStyle}
                    role="button"
                    onLoad={() => {
                      setImageLoaded(imagesFetched);
                    }}
                    onClick={() => {
                      if (images && images.length > 0) {
                        setClickedMedia(images[0].id);
                        setOpen(true);
                      }
                    }}
                  />

                  <Grid container>
                    {images &&
                      survey.isIncludePhotos &&
                      images.length > 1 &&
                      images
                        .filter(
                          (item: PropertyImages) => item.id !== images[0].id,
                        )
                        .map((item) => backGroundImageStyle(item))}
                  </Grid>
                  <Grid container>{attachmentsMobileView()}</Grid>
                </Grid>
              ) : (
                <Grid
                  item
                  sm={12}
                  md={12}
                  lg={12}
                  style={{ margin: 'auto', marginTop: '4px' }}
                >
                  {images.map((img) => (
                    <div
                      style={{
                        backgroundImage: `url('${img.url}')`,
                        marginBottom: 8,
                      }}
                      className={classes.imageStyle}
                    ></div>
                  ))}
                  <div>{attachmentsMobileView()}</div>
                </Grid>
              )}
              <Grid
                ref={propertyDetailsRef}
                item
                sm={isDesktop ? 8 : 12}
                md={8}
                lg={6}
                className={`${classes.outerSpace} ${
                  classes.overflowEffect
                } ${getGridClass(property)}`}
                style={{ backgroundColor: '#FFFFFF' }}
              >
                <div style={{ paddingBottom: '40px' }}>
                  <main className={classes.content} ref={ref}>
                    <mapping-component
                      vendor="mapbox"
                      client-id={BAMWSO2ClientID}
                      secret-id={BAMWSO2SecretID}
                      ref={mapCompEle}
                      width={width}
                      height={isDesktop ? '350' : '183'}
                    ></mapping-component>
                  </main>
                </div>
                <Grid container>
                  <Grid item xs>
                    <Typography
                      variant="h6"
                      className={classes.propertyDetailText}
                    >
                      Property Details
                    </Typography>
                  </Grid>
                  {
                    <div>
                      <Box className={classes.desktopBox}>
                        <Grid
                          item
                          id="SC-Shortlist-Button"
                          onClick={() =>
                            propertyList.length !== 1
                              ? shortListClicked()
                              : onUpdateSurvey()
                          }
                          className={classes.buttonGrid}
                        >
                          {property.metaData?.isClientShortlist ? (
                            <Button
                              variant="contained"
                              size="medium"
                              className={classes.publishShareModalSection}
                              startIcon={<CheckIcon />}
                            >
                              Shortlisted
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              size="medium"
                              className={classes.publishShareModalSection}
                            >
                              Shortlist
                            </Button>
                          )}
                        </Grid>
                        <Tooltip
                          title="Clicking Shortlist indicates interest in this property. You can review these with your brokerage team."
                          arrow
                          placement="top"
                          classes={{
                            tooltip: classes.customWidth,
                            arrow: classes.tipArrow,
                          }}
                        >
                          <InfoOutlinedIcon className={classes.icon} />
                        </Tooltip>
                      </Box>
                    </div>
                  }
                </Grid>

                <InteractivePropertyDetail
                  survey={survey}
                  property={property}
                  key={propertyId}
                />
                {isCommentVisible && propertyComments()}
                {isAmenitiesVisible && propertyAminities()}
                <InteractiveSurveyPropertyImageModal
                  medias={images}
                  clickedMedia={clickedMedia}
                  open={open}
                  setOpen={setOpen}
                />
              </Grid>
            </Grid>
          </Grid>
          {activeAvailabilityRules &&
            (property.availabilities || []).length > 0 && (
            <Grid
              className={`${classes.availabilities} ${classes.paddingBottom125}`}
            >
              <PropertyAvailabilities
                displayFields={
                    activeAvailabilityRules as BHPropertyFieldRule[]
                }
                availabilities={property.availabilities as any[]}
              ></PropertyAvailabilities>
            </Grid>
          )}

          {
            <div>
              <Box className={classes.mobileBox}>
                <Grid
                  item
                  onClick={() =>
                    propertyList.length !== 1
                      ? shortListClicked()
                      : onUpdateSurvey()
                  }
                  className={classes.buttonGrid}
                >
                  {property.metaData?.isClientShortlist ? (
                    <Button
                      variant="contained"
                      size="medium"
                      className={classes.publishShareModalSection}
                      id="SC-Shortlisted-Button"
                      startIcon={<CheckIcon />}
                    >
                      Shortlisted
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="medium"
                      className={classes.publishShareModalSection}
                      id="SC-Shortlist-Button"
                    >
                      Shortlist
                    </Button>
                  )}
                </Grid>
                <Tooltip
                  open={tooltipIsOpen}
                  onOpen={() => setTooltipIsOpen(true)}
                  onClose={() => setTooltipIsOpen(false)}
                  title="Tapping Shortlist indicates interest in this property. You can review these with your brokerage team."
                  arrow
                  placement="top-start"
                  classes={{
                    tooltip: classes.customWidth,
                    arrow: classes.tipArrow,
                  }}
                >
                  <InfoOutlinedIcon
                    className={classes.icon}
                    onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
                  />
                </Tooltip>
              </Box>
            </div>
          }
        </>
      )}
    </>
  );
};

InteractiveSurveyProperty.displayName = 'InteractiveSurveyProperty';
export default InteractiveSurveyProperty;
