import React, { useContext, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useResizeDetector } from 'react-resize-detector';
import { Box } from '@material-ui/core';
import { match } from 'react-router';
import { useHistory } from 'react-router-dom';
import InteractivePropertyList from '../../components/InteractivePropertyList';
import { BAMWSO2ClientID, BAMWSO2SecretID } from '../../config';
import { RouteParams } from '../../routes/interactive.routes';
import { Property } from '../../types';
import * as mapUtil from '../../utilities/map/map';
import { getFeatureImageForProperty } from '../../services/interactive/data-service';
import MapCenter from '../../map/MapControls/MapCenter';
import MapStyles from '../../map/MapControls/MapStyles';
import { BAM_MAP_STYLE_SOURCES } from '../../map/constants/MapStyles';
import { isEmptyObjectFromStr } from '../../utilities';
import InteractiveSurveyContext from '../../hooks/InteractiveSurveyContext';

const drawerWidth = 504;
const drawerSMWidth = 364;
const mediumPlaceholderImg = '/assets/images/image-placeholder-medium.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
    },
    pageTitle: {
      fontSize: 28,
      lineHeight: 1,
      color: theme.palette.primary.main,
      marginBottom: 12,
      marginTop: -10,
    },
    paper: {
      margin: 'auto',
      minWidth: 450,
      marginBottom: 16,
      height: 145,
      [theme.breakpoints.between('sm', 'md')]: {
        height: 145,
        minWidth: 320,
      },
    },
    drawer: {
      zIndex: 1,
      width: drawerWidth,
      paddingTop: '48px',
      flexShrink: 0,
      whiteSpace: 'nowrap',
      [theme.breakpoints.between('sm', 'md')]: {
        width: drawerSMWidth,
      },
    },
    drawerOpen: {
      width: drawerWidth,
      paddingTop: '48px',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.between('sm', 'md')]: {
        width: drawerSMWidth,
      },
      background: theme.palette.grey['50'],
    },
    drawerClose: {
      paddingTop: '48px',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: '48px !important',
      overflowY: 'hidden',
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
      background: theme.palette.grey['50'],
    },
    content: {
      flexGrow: 1,
      height: '100%',
      width: 720,
      position: 'relative',
      [theme.breakpoints.between('sm', 'md')]: {
        width: 360,
      },
    },
    '@media (max-width: 767px)': {
      height: '94.5vh',
      width: '100vw',
    },
    '@media (max-width: 376px)': {
      height: 'calc(100vh - 6.5vh)',
      width: '100vw',
    },
    mapComp: {
      height: 'calc(100vh - 248px)',
      display: 'block',
    },
    propertyBox: {
      width: '100%',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
    actionBar: {
      marginTop: 8,
      marginBottom: 12,
    },
    hideText: {
      marginLeft: 16,
      fontSize: '0.875rem',
      color: theme.palette.text.primary,
    },
    arrowBtn: {
      padding: 6,
      marginBottom: '2px',
      color: theme.palette.text.primary,
    },
    hideMobileView: {
      display: 'none',
      '@media (min-width: 767px)': {
        display: 'block',
      },
    },
    '@global': {
      '.tooltip-box': {
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
      },
      '.tooltip-img': {
        height: '140px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      },
      '.tooltip-address1': {
        fontSize: '1rem',
        lineHeight: 1.25,
        color: theme.palette.primary.dark,
        textAlign: 'left',
        margin: '10px 20px 0px 20px',
      },
      '.tooltip-address2': {
        fontSize: '12px',
        color: theme.palette.grey[800],
        textAlign: 'left',
        padding: '0px 20px 15px 20px',
      },
      '.action-list': {
        padding: '0px 20px 20px 20px',
      },
      '.tooltip-action-view': {
        background: theme.palette.primary.main,
        borderRadius: '2px',
        width: '100%',
        height: '30px',
        color: 'white',
        cursor: 'pointer',
        fontSize: '1rem',
      },
      '.tooltip-action-view:disabled': {
        opacity: 0.8,
        background: '#E3E3E3',
        color: '#868686',
      },
      starIcon: {
        backgroundColor: '#F9DD4B',
      },
    },
  }),
);

const InteractiveSurveyMarketMap: React.FC<Props> = ({ match: { params } }) => {
  const classes = useStyles();
  const history = useHistory();
  const { surveyId } = params as RouteParams;
  const { updateMapPoints, getIconData, getActiveIconColor } = mapUtil.default;
  const [open, setOpen] = React.useState(true);
  const [openPopup, setOpenPopup] = React.useState(0);
  const [properties, setProperties] = React.useState<Property[]>([]);
  const { height, width, ref } = useResizeDetector();
  const { survey } = useContext(InteractiveSurveyContext);
  const mapCompPrv = useRef<any>();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const resetMap = (mapDataStr: string | undefined) => {
    if (
      mapDataStr &&
      typeof JSON.parse(mapDataStr) === 'object' &&
      !isEmptyObjectFromStr(mapDataStr)
    ) {
      const mapData = JSON.parse(mapDataStr);
      if (mapData.mapReset) {
        mapCompPrv.current.zoomToFit();
      }
    }
  };

  useEffect(() => {
    const actionFired = (event: any) => {
      const {
        value: { action, id },
      } = event.detail;
      if (action && id) {
        history.push(`/${surveyId}/property/${id}`);
      }
    };
    let preventMultiTriggerTimer: any = null;
    const pointClickedHandler = (point: any) => {
      clearTimeout(preventMultiTriggerTimer);
      preventMultiTriggerTimer = setTimeout(() => {
        toggleMarkers(point.detail.value.properties.id - 1);
      }, 10);
    };

    if (mapCompPrv?.current) {
      mapCompPrv.current.mapResize(width, height);
      mapCompPrv.current.addEventListener('hostActionTriggered', actionFired);
      mapCompPrv.current.addEventListener('pointClicked', pointClickedHandler);
      resetMap('{"mapReset":true}');
    }
    return () => {
      mapCompPrv.current.removeEventListener(
        'hostActionTriggered',
        actionFired,
      );
      mapCompPrv.current.removeEventListener('pointClicked', pointClickedHandler);
    };
  }, [width, height]);

  const setPropertiesOnMap = () => {
    let propertyList = survey?.properties || [];
    const surveyShortlisted = (localStorage.getItem('shortlisted') === 'true');
    propertyList = propertyList.filter(
      (item: Property) =>
        (!surveyShortlisted && !item.isHidden) ||
        (surveyShortlisted && item.metaData?.isClientShortlist),
    );
    const apiCalls = propertyList.map((item: Property) =>
      getFeatureImageForProperty(item.ownerId, item.id, 'IMAGES'),
    );

    Promise.all(apiCalls)
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          propertyList = propertyList.map((item: Property) => {
            const imgObj = data.find((img: any) => img?.propertyId === item.id);
            const imgUrl = imgObj?.cloudfrontUrl || mediumPlaceholderImg;
            return {
              ...item,
              imgUrl,
            };
          }) as Property[];
        }
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err))
      .finally(() => {
        setProperties(propertyList);
        updateMapPoints(
          propertyList,
          mapCompPrv,
          // survey.marketMapBamData
          '{"mapReset":true}',
        );
        if (mapCompPrv?.current) {
          mapCompPrv.current.enableZoomControls(true);
          mapCompPrv.current.updateMapStyles(
            BAM_MAP_STYLE_SOURCES[survey.marketMapStyle] ||
              BAM_MAP_STYLE_SOURCES.cbreLight,
          );
        }
      });
  };

  useEffect(() => {
    setPropertiesOnMap();
  }, [survey]);

  const onRecenterChange = () => {
    if (mapCompPrv?.current) {
      mapCompPrv.current.zoomToFit();
    }
  };

  const onMapStyleChange = (style: string) => {
    const mapComp = mapCompPrv.current;
    mapComp.updateMapStyles(BAM_MAP_STYLE_SOURCES[style]);
  };

  const removePopup = () => {
    const ele = mapCompPrv.current.querySelector('.custom-popover');
    if (ele) {
      ele.parentElement.removeChild(ele);
    }
    setOpenPopup(0);
    mapCompPrv.current.getPoints().then((points: any) => {
      /* eslint no-param-reassign: "error" */
      points.features.map((prop: any) => {
        if (prop.properties.markerConfig.iconColor) {
          const property: Property[] = survey.properties.filter(
            (item) => item.id === prop.id);
          prop.properties.markerConfig.iconColor = getIconData(property[0]).iconColor;
        }
        return null;
      });
      points.features.map((_: any, index: number) => mapCompPrv.current.updatePoint(points.features[index]));
    });
  };

  const toggleMarkers = async (pinNum: number) => {
    const map = mapCompPrv.current;
    removePopup();
    const points = Promise.resolve(map.getPoints());
    const popupNumber = pinNum + 1;

    // eslint-disable-next-line no-shadow
    await points.then((point) => {
      /* eslint no-param-reassign: "error" */
      point.features.map((_: any, index: number) => mapCompPrv.current.updatePoint(point.features[index]));
      const property: Property[] = survey.properties.filter(
        (item) => item.id === point.features[pinNum].id);
      point.features[pinNum].properties.markerConfig.iconColor = getActiveIconColor(property[0]);
      map.updatePoint(point.features[pinNum]);
      setOpenPopup(point.features[pinNum].properties.id);
      map.togglePopup(popupNumber);
    });
  };

  return (
    <>
      {survey?.id && (
        <div className={classes.root}>
          <main className={classes.content} ref={ref}>
            <mapping-component
              vendor="mapbox"
              client-id={BAMWSO2ClientID}
              secret-id={BAMWSO2SecretID}
              width={width && width > 676 ? width : '100vw'}
              height={width && width > 676 ? height : '100vh'}
              ref={mapCompPrv}
              class={classes.mapComp}
              onClick={removePopup}
            >
              <MapCenter onRecenterChange={onRecenterChange} />
              <MapStyles
                onMapStyleChange={onMapStyleChange}
                survey={survey}
              />
            </mapping-component>
          </main>
          <Drawer
            variant="permanent"
            anchor="right"
            className={clsx(classes.drawer, classes.hideMobileView, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.actionBar}>
              {open ? (
                <div>
                  <span className={classes.hideText}>Hide</span>
                  <IconButton
                    onClick={handleDrawerClose}
                    className={classes.arrowBtn}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </div>
              ) : (
                <IconButton
                  onClick={handleDrawerOpen}
                  className={classes.arrowBtn}
                >
                  <ChevronLeftIcon />
                </IconButton>
              )}
            </div>
            <Box
              className={classes.propertyBox}
              style={{ visibility: open ? 'visible' : 'hidden' }}
            >
              <InteractivePropertyList
                propertyList={properties}
                shortListPins={setPropertiesOnMap}
                cardClicked={(pin: number) => toggleMarkers(pin)}
                cardSelected={openPopup}
              />
            </Box>
          </Drawer>
        </div>
      )}
    </>
  );
};

type Props = {
  match: match;
};

InteractiveSurveyMarketMap.displayName = 'InteractiveSurveyMarketMap';
export default InteractiveSurveyMarketMap;
