import { PDFPaperSize } from '../../data/constants';
import {
  BAM_MAP_STYLE_LIST,
  BAM_MAP_STYLE_SOURCES,
  defaultMapCenter,
  defaultMapTextSize,
  defaultZoomLevel,
  iconColorActive,
  iconColorInactive,
  iconColorYellow,
  mapIgnoredActionStyles,
} from '../../map/constants/MapStyles';
import {
  exportMarketMap,
} from '../../services/media-service';
import { MediaFile, Property, Survey } from '../../types';
import { MarketMapStatePatch, MediaState } from '../../types/interactive/marketMap';
import { InteractiveProperty } from '../../types/interactive/property';
import {
  checkCoordinateIsvalid,
  getArrayChunks,
  isEmptyObjectFromStr,
} from '../index';

const mediumPlaceholderImg = '/assets/images/image-placeholder-medium.png';

const freezeMarkers = (mapEle: any) => {
  const disableDrag = async () => {
    if (mapEle && typeof mapEle.toggleAllDragPoints === 'function') {
      mapEle.toggleAllDragPoints(true);
    }
  };
  // eslint-disable-next-line no-console
  disableDrag().catch((err) => console.error(err));
};

const getPropertyAddress1 = (value: string = '') => {
  const address = value ? value.toString() : '';
  return address.replace(/<|>/g, '');
};

const getPropertyAddress2 = (property: Property) => {
  const { city = '', state = '', postalCode = '' } = property;
  const address = `${city ? city.toString() : ''}, ${state ? state.toString() : ''} ${postalCode ? postalCode.toString() : ''}`;
  return address.replace(/<|>/g, '');
};

const getIconData = (property: Property) => {
  let bamMapData = {
    iconSize: defaultMapTextSize,
    iconColor: property.metaData?.isClientShortlist
      ? '#112F23'
      : iconColorInactive,
    radius: '',
    radiusLabel: false,
    radiusColor: '',
    radiusLength: '',
  };
  if (property && property.metaData?.isCurrentLocation) {
    bamMapData.iconColor = iconColorYellow;
  }
  if (
    property.metaData?.bamMapData &&
    typeof JSON.parse(property.metaData?.bamMapData) === 'object' &&
    'iconColor' in JSON.parse(property.metaData?.bamMapData) &&
    !isEmptyObjectFromStr(property.metaData?.bamMapData)
  ) {
    bamMapData = JSON.parse(property.metaData?.bamMapData);
  }
  return bamMapData;
};

const getActiveIconColor = (property: Property) => {
  let color = iconColorActive;
  if (property?.metaData?.isCurrentLocation) {
    color = iconColorYellow;
    if (property?.metaData?.bamMapData) {
      const bamMapData = JSON.parse(property.metaData?.bamMapData || '');
      color =
        property.metaData?.isCurrentLocation &&
        !property.metaData?.isClientShortlist
          ? iconColorYellow
          : bamMapData.iconColor;
    }
  }
  return color;
};

const updateMapPoints = (
  properties: Property[],
  mapCompRef: any,
  mapDataStr?: string,
  zoomLevel?: number,
  mapTextSize?: number,
) => {
  const points = {
    type: 'FeatureCollection',
    features: [] as any,
  };

  let mapData = {
    mapCentroid: getCenterPoint(properties),
    mapZoomLevel: zoomLevel ?? defaultZoomLevel,
    mapReset: false,
  };

  if (
    mapDataStr &&
    typeof JSON.parse(mapDataStr) === 'object' &&
    !isEmptyObjectFromStr(mapDataStr)
  ) {
    mapData = JSON.parse(mapDataStr);
  }

  let pointIndex = 0;
  properties.forEach((property: Property, index) => {
    // Only geocode when a coordinate doesn't already exist
    if (
      !property.isHidden &&
      ((property.latitude && property.longitude) ||
        (property.address1 &&
          ((property.city && property.state) ||
            (property.postalCode && property.postalCode.length >= 5))))
    ) {
      const { imgUrl } = property as InteractiveProperty;

      if (!property.metaData?.isCurrentLocation) {
        pointIndex += 1;
      }
      let popupStr = `
      <div class="tooltip-box">
        <div class="tooltip-img" style="background-image: url('${imgUrl}')" ></div>
        <div class="tooltip-address1">${property.address1}</div>
        <div class="tooltip-address2">${property.city}, ${property.state} ${property.postalCode}</div>
        <div class="action-list"><button class="tooltip-action tooltip-action-view" data-id="${property.id}" data-action="view-detail">See Details</button></div>
      </div>
    `;
      if (property.metaData?.isCurrentLocation) {
        popupStr = `
      <div class="tooltip-box">
        <div class="tooltip-img"  style="background-image: url('${imgUrl}')"  ></div>
        <div class="tooltip-address1">${property.address1}</div>
        <div class="tooltip-address2">${property.city}, ${property.state} ${property.postalCode}</div>
        <div class="action-list"><button disabled class="tooltip-action tooltip-action-view" data-id="${property.id}" data-action="view-detail">See Details</button></div>
      </div>
    `;
      }
      const pointData = {
        id: property.id,
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [Number(property.longitude), Number(property.latitude)],
        },
        properties: {
          id: pointIndex,
          title: 'Mapbox',
          group: 1,
          order: index,
          description: `${property.city} ${property.state}`,
          location: {
            address: property.address1,
            city: property.city,
            stateprov: property.state,
            postalcode: property.postalCode,
            country: 'USA',
          },

          markerConfig: {
            popup: popupStr,
            iconSize: '24',
            iconColor: property.metaData?.isClientShortlist
              ? '#003F2D'
              : getIconData(property).iconColor,
            icon: property.metaData?.isCurrentLocation
              ? 'starIcon'
              : 'emptyIcon',
            textContent: pointIndex,
            textX: '27',
            textY: '38',
            textSize: mapTextSize ?? defaultMapTextSize,
            closeButton: false,
            radiusColor: getIconData(property).radiusColor,
            radiusLabel: getIconData(property).radiusLabel,
            radius: getIconData(property).radius,
            radiusLength: getIconData(property).radiusLength,
          },
          disabled: true,
        },
      };
      if (
        checkCoordinateIsvalid(property.latitude) &&
        checkCoordinateIsvalid(property.longitude) &&
        points.features[0]
      ) {
        // @ts-ignore
        delete points.features[0].geometry.coordinates;
      }

      points.features.push(pointData);
    }
  });

  const mapComp = mapCompRef.current;
  // eslint-disable-next-line valid-typeof
  if (mapComp && typeof mapComp.addPoints === 'function') {
    // REMOVE ALL POINTS
    mapComp.removeAllPoints().then(() => {
      if (properties?.length > 0 && points.features.length > 0) {
        mapComp.addPoints(points, false).then(() => {
          if (mapData.mapReset) {
            mapComp.zoomToFit();
          } else {
            mapComp.updateMapCenter(mapData.mapCentroid);
            mapComp.updateMapZoom(mapData.mapZoomLevel);
          }
        });
      } else if (mapData.mapReset) {
        mapComp.zoomToFit();
      } else {
        mapComp.updateMapCenter(mapData.mapCentroid);
        mapComp.updateMapZoom(mapData.mapZoomLevel);
      }
    });
  }
};

const getCenterPoint = (properties: Property[]) => {
  if (Array.isArray(properties) && properties.length > 0) {
    const propertiesWithCoordinates = properties.filter(
      (p) => p.longitude && p.latitude && !p.isHidden,
    );
    const calDefaultCenter = propertiesWithCoordinates.reduce(
      (acc, p) => [
        acc[0] + parseFloat(p.longitude),
        acc[1] + parseFloat(p.latitude),
      ],
      [0.0, 0.0],
    );
    return [
      calDefaultCenter[0] / propertiesWithCoordinates.length,
      calDefaultCenter[1] / propertiesWithCoordinates.length,
    ];
  }
  return defaultMapCenter;
};

const getMapPointsWithImages = (survey: Survey, mediaState?: MediaState) =>
  new Promise((resolve) => {
    const points = {
      type: 'FeatureCollection',
      features: [] as any,
    };
    if (Array.isArray(survey.properties) && survey.properties.length > 0) {
      let propertyList = survey.properties.filter(
        (prop: Property) => prop.isHidden === undefined || !prop.isHidden,
      );
      let pointIndex = 0;
      propertyList = propertyList.map((item: Property) => {
        const propertyId = `${item.id}`;
        const imgObj =
          mediaState && mediaState[propertyId]
            ? // eslint-disable-next-line max-len
            mediaState[propertyId].images.find(
              (img: MediaFile) =>
                img.isHidden !== undefined && img.isHidden !== true,
            )
            : null;
        const imgUrl = imgObj?.cloudfrontUrl || mediumPlaceholderImg;
        return {
          ...item,
          imgUrl,
        } as Property;
      });
      propertyList.forEach((property: Property, index) => {
        // Only geocode when a coordinate doesn't already exist
        if (
          !property.isHidden &&
          ((property.latitude && property.longitude) ||
            (property.address1 &&
              ((property.city && property.state) ||
                (property.postalCode && property.postalCode.length >= 5))))
        ) {
          if (!property.metaData?.isCurrentLocation) {
            pointIndex += 1;
          }
          const pointData = {
            id: property.id,
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [
                Number(property.longitude),
                Number(property.latitude),
              ],
            },
            properties: {
              id: pointIndex,
              title: 'Mapbox',
              group: 1,
              order: index,
              description: `${property.city} ${property.state}`,
              location: {
                address: property.address1,
                city: property.city,
                stateprov: property.state,
                postalcode: property.postalCode,
                country: 'USA',
              },
              markerConfig: {
                popup: `
                  <div class="tooltip-box">
                    <div class="tooltip-img"  style="background-image: url(${
  property.imgUrl
})" ></div>
                    <div class="tooltip-address1">${getPropertyAddress1(
    property.address1,
  )}</div>
                    <div class="tooltip-address2">${getPropertyAddress2(
    property,
  )}</div>
                    <div class="popover-base-padding action-container">
                    <button id="utility-edit-property" class="tooltip-action transparent-btn" data-id="${
  property.id
}" data-action="edit-property">
                    <img class="btn-event" src="/assets/images/edit-icon.svg">
                    </button>
                    <button id="utility-style-property" class="tooltip-action transparent-btn" data-id="${
  property.id
}" data-action="style-icon">
                    <img class="btn-event" src="/assets/images/paintbush-icon.svg">
                    </button>
                    <button id="utility-radius-property" class="tooltip-action transparent-btn" data-id="${
  property.id
}" data-action="radius-ring">
                    <img class="btn-event" src="/assets/images/radiusRingIcon.svg">
                    </button>
                    <button id="utility-toggle-property" class="tooltip-action transparent-btn" data-id="${
  property.id
}" data-action="toggle-property">
                    <img class="btn-event" src="/assets/images/remove_eye-icon.svg">
                    </button>
                    </div>
                  </div>
                `,
                iconSize: getIconData(property).iconSize,
                iconColor: property.metaData?.isClientShortlist
                  ? '#112F23'
                  : getIconData(property).iconColor,
                icon: property.metaData?.isCurrentLocation ? 'starIcon' : 'emptyIcon',
                textContent: pointIndex,
                textX: '27',
                textY: '38',
                textSize: '32',
                closeButton: false,
                radiusColor: getIconData(property).radiusColor,
                radiusLabel: getIconData(property).radiusLabel,
                radius: getIconData(property).radius,
                radiusLength: getIconData(property).radiusLength,
              },
            },
          };
          if (
            checkCoordinateIsvalid(property.latitude) &&
            checkCoordinateIsvalid(property.longitude) &&
            points.features[0]
          ) {
            // @ts-ignore
            delete points.features[0].geometry.coordinates;
          }
          points.features.push(pointData);
        }
      });
      resolve(points);
    } else {
      resolve(points);
    }
  });

const getMapPointsOnly = (survey: Survey) =>
  new Promise((resolve) => {
    const points = {
      type: 'FeatureCollection',
      features: [] as any,
    };
    if (Array.isArray(survey.properties) && survey.properties.length > 0) {
      const propertyList = survey.properties.filter(
        (prop: Property) => prop.isHidden === undefined || !prop.isHidden,
      );
      let pointIndex = 0;
      propertyList.forEach((property: Property, index) => {
        // Only geocode when a coordinate doesn't already exist
        if (
          !property.isHidden &&
          ((property.latitude && property.longitude) ||
            (property.address1 &&
              ((property.city && property.state) ||
                (property.postalCode && property.postalCode.length >= 5))))
        ) {
          if (!property.metaData?.isCurrentLocation) {
            pointIndex += 1;
          }
          const pointData = {
            id: property.id,
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [
                Number(property.longitude),
                Number(property.latitude),
              ],
            },
            properties: {
              id: property.metaData?.isCurrentLocation ? -1 : pointIndex,
              title: 'Mapbox',
              group: 1,
              order: index,
              description: `${property.city} ${property.state}`,
              location: {
                address: property.address1,
                city: property.city,
                stateprov: property.state,
                postalcode: property.postalCode,
                country: 'USA',
              },
              markerConfig: {
                popup: '',
                iconSize: getIconData(property).iconSize,
                iconColor: (property.metaData?.isClientShortlist ? '#112F23' : getIconData(property).iconColor),
                icon: property.metaData?.isCurrentLocation ? 'starIcon' : 'emptyIcon',
                textContent: pointIndex,
                textX: '27',
                textY: '38',
                textSize: '32',
                closeButton: false,
                radiusColor: getIconData(property).radiusColor,
                radiusLabel: getIconData(property).radiusLabel,
                radius: getIconData(property).radius,
                radiusLength: getIconData(property).radiusLength,
              },
            },
          };
          if (
            checkCoordinateIsvalid(property.latitude)
            && checkCoordinateIsvalid(property.longitude) && points.features[0]
          ) {
            // @ts-ignore
            delete points.features[0].geometry.coordinates;
          }
          points.features.push(pointData);
        }
      });
      resolve(points);
    } else {
      resolve(points);
    }
  });

const resetMap = (survey: Survey, map: any) => {
  if (map && typeof map.addPoints === 'function') {
    // REMOVE ALL POINTS
    map.removeAllPoints().then(() => {
      getMapPointsWithImages(survey).then((points: any) => {
        if (points.features.length > 0) {
          map?.addPoints(points, false).then(() => {
            map?.zoomToFit();
            freezeMarkers(map);
          });
        }
      });
    });
  }
};

const getMapSize = (survey: MarketMapStatePatch): [number, number] => {
  let output: [number, number] = [1056, 816];
  if (survey.pdfPaperSize === PDFPaperSize.Landscape) {
    if (survey.isIncludeHeader && survey.isIncludeLegend) {
      output = [730, 672];
    } else if (!survey.isIncludeHeader && !survey.isIncludeLegend) {
      output = [1056, 766];
    } else if (survey.isIncludeHeader && !survey.isIncludeLegend) {
      output = [1056, 672];
    } else if (!survey.isIncludeHeader && survey.isIncludeLegend) {
      output = [730, 766];
    }
  } else if (survey.pdfPaperSize === PDFPaperSize.Portrait) {
    if (
      survey.isIncludeHeader &&
      survey.isIncludeLegend &&
      survey.legendHeight
    ) {
      const height = 1056 - survey.legendHeight - 48 - 94;
      output = [814, height];
    } else if (
      !survey.isIncludeHeader &&
      survey.isIncludeLegend &&
      survey.legendHeight
    ) {
      const height = 1008 - survey.legendHeight - 48;
      output = [814, height];
    } else if (!survey.isIncludeHeader && !survey.isIncludeLegend) {
      output = [814, 1006];
    } else if (survey.isIncludeHeader && !survey.isIncludeLegend) {
      output = [814, 912];
    }
  }
  return output;
};

const getMapEleBoxSize = (prefix: string, index: number): [number, number] => {
  let output: [number, number] = [0, 0];
  const mapEle = document.querySelectorAll(`#${prefix}-${index} #map`) as any;
  if (mapEle && mapEle[0]) {
    const ele = mapEle[0] as HTMLElement;
    output = [ele.clientWidth, ele.clientHeight];
  }
  return output;
};

const resizeMap = (prefix: string, index: number, mapSize: [number, number]) =>
  new Promise((resolve) => {
    const mapEle = document.getElementById(`${prefix}-${index}`) as any;
    if (mapEle && mapEle.mapResize) {
      mapEle.mapResize(mapSize[0], mapSize[1]).then(() => {
        resolve('');
      });
    } else {
      resolve('');
    }
  });

const recenterMap = (prefix: string, index: number) => {
  const mapEle = document.getElementById(`${prefix}-${index}`) as any;
  if (mapEle && mapEle.zoomToFit) {
    mapEle.zoomToFit();
    freezeMarkers(mapEle);
  }
};

const updateMapZoom = (prefix: string, index: number, zoomLevel: number) => {
  const mapEle = document.getElementById(`${prefix}-${index}`) as any;
  if (mapEle && mapEle.updateMapZoom) {
    mapEle.updateMapZoom(zoomLevel);
    freezeMarkers(mapEle);
  }
};

const getMapZoom = (prefix: string, index: number) => {
  const mapEle = document.getElementById(`${prefix}-${index}`) as any;
  if (mapEle && mapEle.updateMapZoom) {
    return mapEle.zoom;
  }
  return defaultZoomLevel;
};

const addMapPotins = (prefix: string, index: number, points: any[]) => {
  const mapEle = document.getElementById(`${prefix}-${index}`) as any;
  if (mapEle && mapEle.addPoints) {
    mapEle.removeAllPoints().then(() => {
      mapEle
        .addPoints(points, false)
        .then(() => {
          mapEle.zoomToFit();
          freezeMarkers(mapEle);
        })
        .catch(() => {
          mapEle.zoomToFit();
        });
    });
  }
};

const addMapPotinsOnly = (prefix: string, index: number, points: any[]) => {
  const mapEle = document.getElementById(`${prefix}-${index}`) as any;
  if (mapEle && mapEle.addPoints) {
    mapEle.removeAllPoints().then(() => {
      mapEle
        .addPoints(points, false)
        .then(() => {
          freezeMarkers(mapEle);
        })
        .catch(() => {
          freezeMarkers(mapEle);
        });
    });
  }
};

const updateMapCenter = (
  prefix: string,
  index: number,
  mapCenter: [number, number],
) => {
  const mapEle = document.getElementById(`${prefix}-${index}`) as any;
  if (mapEle && mapEle.updateMapCenter) {
    mapEle.updateMapCenter(mapCenter);
  }
};

const updateMapStyle = async (
  prefix: string,
  index: number,
  mapStyle: string,
) => {
  const mapEle = document.getElementById(`${prefix}-${index}`) as any;
  if (mapEle && mapEle.updateMapStyles) {
    return mapEle.updateMapStyles(BAM_MAP_STYLE_SOURCES[mapStyle]);
  }
  return null;
};

const getMapCount = (
  isIncludeLegend: boolean,
  isIncludeHeader: boolean,
  properties: any[],
) => {
  const propertyChunks = getArrayChunks(properties, isIncludeHeader ? 12 : 14);

  return isIncludeLegend ? propertyChunks.length : 1;
};

const getMapStyle = (mapStyle: any) => {
  if (!BAM_MAP_STYLE_LIST.includes(mapStyle)) {
    return 'cbreLight';
  }
  return mapStyle;
};

const processMapImg = (state: any) => {
  let payload: any;
  const curMapSize = getMapEleBoxSize('marketmap', 0);
  let mapStyle = getMapStyle(state.marketMapStyle);
  if (mapStyle === 'mapboxStreets') {
    mapStyle = 'mapbox://styles/mapbox/streets-v10';
  } else if (mapStyle === 'mapboxDark') {
    mapStyle = 'mapbox://styles/mapbox/dark-v9';
  } else if (mapStyle === 'mapboxGray') {
    mapStyle = 'mapbox://styles/mapbox/light-v9';
  }
  const mapEle = document.getElementById('marketmap-0') as any;
  if (mapEle && typeof mapEle.getPoints === 'function') {
    // eslint-disable-next-line max-len
    Promise.all([
      getMapPointsOnly(state),
      mapEle.getMapCenter(),
      mapEle.getMapZoom(),
    ]).then((data: any[]) => {
      payload = {
        surveyId: state.id,
        bamRequest: {
          map: {
            mapStyle,
            mapCenter: data[1],
            mapZoom: data[2],
            mapPitch: 0,
            mapBearing: 0,
            mapWidth: curMapSize[0],
            mapHeight: curMapSize[1],
            fileType: 'png',
          },
          points: data[0] ? data[0].features : [],
        },
      };
      // eslint-disable-next-line no-console
      exportMarketMap(payload).then(() => console.log('> map image processed'));
    });
  }
};

const getMapInstance = (prefix: string, index: number) => {
  const mapEle = document.getElementById(`${prefix}-${index}`) as any;
  if (mapEle && mapEle.updateMapZoom) {
    return mapEle;
  }
  return null;
};

const getMapState = (prefix: string, index: number) =>
  new Promise((resolve, reject) => {
    const mapEle = getMapInstance(prefix, index);
    if (mapEle && mapEle.getMapCenter) {
      Promise.all([mapEle.getMapCenter(), mapEle.getMapZoom()]).then((data) => {
        const mapData = {
          mapCentroid: data[0],
          mapZoomLevel: data[1],
        };
        resolve(mapData);
      });
    } else {
      reject(Error);
    }
  });

const isNonMapCtrlClicked = (eleClassStr: string) => {
  let isClicked = false;
  if (eleClassStr) {
    if (typeof eleClassStr !== 'string') {
      isClicked = true;
    } else {
      const classes = eleClassStr.split(' ');
      if (classes && Array.isArray(classes) && classes.length > 0) {
        classes.forEach((item: string) => {
          if (mapIgnoredActionStyles.includes(item)) {
            isClicked = true;
          }
        });
      }
    }
  }
  return isClicked;
};

export default {
  updateMapPoints,
  getCenterPoint,
  getMapPointsWithImages,
  getMapPointsOnly,
  resetMap,
  getMapSize,
  getMapStyle,
  resizeMap,
  recenterMap,
  updateMapZoom,
  updateMapCenter,
  getMapCount,
  getMapZoom,
  updateMapStyle,
  addMapPotins,
  addMapPotinsOnly,
  getMapEleBoxSize,
  processMapImg,
  getMapInstance,
  getMapState,
  getIconData,
  getActiveIconColor,
  freezeMarkers,
  isNonMapCtrlClicked,
};
