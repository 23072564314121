import styled from '@emotion/styled';
import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { match } from 'react-router';
import { useHistory } from 'react-router-dom';
import { InteractiveLogin } from '../../types/interactive/login';
import { login } from '../../services/interactive/data-service';

export interface InteractiveSurveyCallbackProps {
  match: match;
}

export type CallbackRouteParams = {
  passcode?: string;
  surveyId?: string;
};

const StyledSignedRequest = styled.div`
  margin-top: 500;
`;

const InteractiveSurveyCallback: React.FC<InteractiveSurveyCallbackProps> = ({
  match: { params },
}) => {
  const history = useHistory();
  const { surveyId, passcode } = params as CallbackRouteParams;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [text] = useState<string>('');
  useEffect(() => {
    if (passcode) {
      if (passcode && surveyId) {
        const loginData: InteractiveLogin = {
          surveyId,
          passcode,
        };
        login(loginData)
          .then((res: any) => {
            const { interactiveToken } = res;
            if (res) {
              localStorage.setItem('interactiveToken', interactiveToken);
              setTimeout(() => history.push(`/${surveyId}`), 800);
            }
          })
          .catch();
      }
    }
  }, [history]);

  return (
    <StyledSignedRequest>
      {!text ? (
        <h1 style={{ marginTop: 200, textAlign: 'center' }}>
          <CircularProgress />
        </h1>
      ) : (
        <h2 style={{ marginTop: 200 }}> {text} </h2>
      )}
    </StyledSignedRequest>
  );
};

export default InteractiveSurveyCallback;
