import {
  PropertyPatch,
  Survey,
} from '../../types';
import { shortlistProperty } from './data-service';
import { UseSurveyAPI } from './useSurveyAPI';

export type UsePropertyAPI = {
  // @ts-ignore
  updateInteractiveProperty: (survey: Survey, property: PropertyPatch, surveyId: string) => void;
};

export type UsePropertiesAPIProps = {
  setSurvey: UseSurveyAPI['setSurvey'];
  survey: Survey;
  updatePropertyFieldRule: any;
};

const logError = (message?: string) => (error: Error) =>
  // eslint-disable-next-line no-console
  console.error(message || 'Error', error);

export default function usePropertyAPI({
  setSurvey,
}: UsePropertiesAPIProps): UsePropertyAPI {
  const updateInteractiveProperty = (survey: Survey, property: PropertyPatch, surveyId: string) => {
    const newSurvey = {
      ...survey,
    };
    shortlistProperty(property, surveyId)
      .then(() => {
        const updatedSurvey = {
          ...newSurvey,
          properties: newSurvey.properties,
        };

        setSurvey(updatedSurvey);
      },
      ).catch((error) => {
        logError('Failed to update property')(error);
      });
  };
  return {
    updateInteractiveProperty,
  };
}
