/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { isFinite } from 'lodash';
import { Property } from '../../types';

/// //////// sub utility ///////////
function checkValidNumber(value: string) {
  const fvalue = parseFloat(value);
  if (!Number.isNaN(fvalue) && isFinite(fvalue)) {
    return true;
  }
  return false;
}

export function dateFormatter(data: any) {
  if (data?.includes('T') && data?.includes('Z')) {
    const Value = data?.sOlice(0, data.indexOf('T'));
    const dateParts = Value?.split('-');
    const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
    const dateFormat = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;

    data = dateFormat;
    return data;
  }

  // return strings those are not date strings
  return data;
}

function getDecorator(attrKey: string) {
  let prefix = '';
  let suffix = '';
  let possibleInteger = true;

  if (['askingRent', 'askingPrice'].includes(attrKey)) {
    prefix = '$';
    suffix = '/SF';
    possibleInteger = false;
  } else if (
    [
      'minTotalAvailableSpace',
      'maxTotalAvailableSpace',
      'totalAvailableSpace',
      'propertySize',
      'netRentableArea',
      'typicalFloorSize',
      'vacantSpace',
    ].includes(attrKey)
  ) {
    suffix = ' SF';
  } else if (['vacantPercentage'].includes(attrKey)) {
    suffix = '%';
    possibleInteger = false;
  } else if (['opex'].includes(attrKey)) {
    prefix = '$';
    possibleInteger = false;
  } else {
    return;
  }

  return {
    prefix,
    suffix,
    possibleInteger,
    fractionDigits: possibleInteger ? 1 : 2,
  };
}

export function refineNumberString(
  value: string,
  possibleInteger: boolean,
  fractionDigits: number,
): string {
  if (!value) return '';
  const fvalue = parseFloat(value);

  if (possibleInteger) {
    const nvalue = parseInt(value, 10);
    if (fvalue === nvalue) {
      return nvalue.toLocaleString('en-US');
    }
  }

  const localeStringOpts = {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  };
  return fvalue.toLocaleString('en-US', localeStringOpts);
}

export function getValidNumberString(
  _value: string,
  possibleInteger: boolean,
  fractionDigits: number,
): string {
  const value = _value || ''; // .trim()

  const others = value.match(/[^0-9.,]/g) || [];
  if (others.length > 0) {
    return '';
  }

  const svalue = value.replace(/[^0-9.,]/g, '');
  if (!checkValidNumber(svalue)) {
    return '';
  }
  return refineNumberString(svalue, possibleInteger, fractionDigits);
}

export function getLocaleStringForValue(
  value: string,
  possibleInteger: boolean,
  fractionDigits: number,
): string {
  const tempVal = value ? value?.replace(/,/g, '') : '';
  const refinedString = getValidNumberString(
    tempVal,
    possibleInteger,
    fractionDigits,
  );
  return refinedString || value;
}
const getSingletonValue = (attrKey: string, value: string) => {
  const decorator = getDecorator(attrKey);
  if (!decorator) {
    return value;
  }
  const { prefix, suffix, possibleInteger, fractionDigits } = decorator;

  if (value) {
    // value = value ? value?.replace(/,/g, '') : '';
    const svalue = getValidNumberString(
      value ? value?.replace(/,/g, '') : '',
      possibleInteger,
      fractionDigits,
    );
    if (svalue) {
      return `${prefix}${svalue}${suffix}`;
    }
    return value;
  }

  return '';
};

function getTotalAvailableValue(min: string, max: string, value: string) {
  const attrKey = 'totalAvailableSpace';

  let svalue = getSingletonValue(attrKey, value);
  if (svalue) {
    return svalue;
  }

  // formula based generation
  const decorator = getDecorator(attrKey)!;
  const { prefix, suffix, possibleInteger, fractionDigits } = decorator;

  min = min ? min?.replace(/,/g, '') : '';
  max = max ? max?.replace(/,/g, '') : '';

  const smin = getValidNumberString(min, possibleInteger, fractionDigits);
  const smax = getValidNumberString(max, possibleInteger, fractionDigits);

  svalue = '';

  if (smin) {
    if (smax) {
      svalue = `${smin} - ${smax}`;
    } else {
      svalue = `${smin}`;
    }
  } else if (smax) {
    svalue = `${smax}`;
  }

  if (svalue) {
    return `${prefix}${svalue}${suffix}`;
  }
  return '';
}

export function addNewLine(value: string) {
  if (!value) return '';
  const match: any = value.match(/, \d/);
  if (match && match.index !== -1 && value.endsWith('SF')) {
    return `${value.substring(0, match.index + 1)}\r\n${value.substring(
      match.index + 1,
      value.length,
    )}`;
  }
  return value;
}

/// ////////
export function getPropertyValueForDisplay(
  attrKey: string,
  property: Property,
) {
  if (!property) return '';
  return property[attrKey] || property.data?.[attrKey];
}

export function getPropertyValueForDisplay2(
  attrKey: string,
  property: Property,
) {
  return getPropertyValueForDisplay(attrKey, property);
}
